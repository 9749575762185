<script setup lang="ts">
const localePath = useLocalePath()
const { trackEvent } = useTracking()
const { locale, t } = useI18n()

const searchPath = localePath({
  name: 'search',
  query: {
    facetFilter: `searchable_tags.2.${locale.value}: ${locale.value === 'fr' ? 'Vue mer' : 'Sea view'}`,
  },
})
const hotDealsPath = computed(() => {
  switch (locale.value) {
    case 'en':
      return '/en/luxury-villas-rentals/book-now-for-summer'
    case 'fr':
      return '/fr/location-villas-luxe/reserver-pour-l-ete'
    default:
      // This should never happen
      return searchPath
  }
})

const onClickHotDeals = () =>
  trackEvent({
    event: 'homepage_favourites_click',
    suggestion: 'house',
  })
</script>

<template>
  <BaseContainer class="bg-gray-100 py-8 lg:max-w-full">
    <BaseRow class="lg:items-center lg:justify-center">
      <BaseCol cols="24" md="12" sm="24">
        <BaseSectionContent :title="t('title')">
          <template #header>
            <p class="mb-8 max-w-screen-sm">
              {{ t('text') }}
            </p>
            <BaseButton
              class="items-center text-lg"
              color="secondary"
              size="xl"
              :to="hotDealsPath"
              @click.prevent="onClickHotDeals"
            >
              {{ t('cta') }}
            </BaseButton>
          </template>
        </BaseSectionContent>
      </BaseCol>
      <BaseCol cols="24" md="12" sm="24" class="pt-8 sm:pt-0">
        <BaseNuxtImg
          alt="Summer bestsellers"
          class="lg:p-20"
          height="365"
          loading="lazy"
          src="production/assets/images/homepage/summer-bestsellers-2025.webp"
          width="547"
        />
      </BaseCol>
    </BaseRow>
  </BaseContainer>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Summer lovin'",
    "text": "Spring is in the air and summer is just around the corner! Get into summer mode and book your dream holiday home under the sun.",
    "cta": "Explore our homes"
  },
  "fr": {
    "title": "Summer lovin'",
    "text": "Le printemps est déjà dans l'air et l'été arrive à grands pas ! Activez le mode sunshine et réservez votre maison de vacances parfaite.",
    "cta": "Explorez nos maisons"
  }
}
</i18n>
