<script setup lang="ts">
const localePath = useLocalePath()
const { trackEvent } = useTracking()

const trackWhereToLeave = () => {
  trackEvent({
    category: 'General',
    event: 'typeform_homepage_click',
  })
}
</script>

<template>
  <BaseButton
    :to="localePath({ name: 'typeform' })"
    target="_blank"
    variant="link"
    @mousedown="trackWhereToLeave"
  >
    <div class="where-to-leave">
      <BaseIcon name="globe" />
      {{ $t('home.where_to') }}
      <span class="rounded-sm bg-white/10 px-2 py-0.5">
        {{ $t('footer.blog') }}
      </span>
    </div>
  </BaseButton>
</template>

<style scoped>
.where-to-leave {
  @apply flex items-center gap-2 rounded bg-gray-700/50 px-2 py-1.5 text-white text-md hover:bg-gray-700/60;
}
.where-to-leave:hover > span {
  @apply bg-opacity-20;
}
</style>
